import React from 'react';
import videojs from 'video.js'
import 'video.js/dist/video-js.css';
import './info.scss'
import flvjs from 'flv.js';
interface State {
  playing: boolean,
}
interface Props {
  className: string,
  style: Object,
  // https://www.gsteps.live/app/s1.m3u8
  m3u8Url: string,
  flvUrl: string,
  poster: string,
  autoplay: boolean,
  controls: boolean,
  videoMirror: number,
}
export default class VideoPlayer extends React.Component<Props, State> {
  player:any = null;
  videoNode:any = null;
  isFlvMode = false;  //可以播放 flv 格式
  config = {
    enableWorker: true,
    enableStashBuffer: false,
    stashInitialSize: 128,
    isLive: true,
    autoCleanupMaxBackwardDuration: 60,
    autoCleanupMinBackwardDuration: 30,
    statisticsInfoReportInterval: 2000,
  };
  constructor(p:any) {
    super(p);
    this.state = {
      playing: false,
    }
  }

  componentDidMount() {
    // instantiate Video.js
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      if(this.player.destroy) {
        this.player.destroy()
      }
      if(this.player.dispose) {
        this.player.dispose()
      }
    }
  }

  initVideo = ($video:any) => {

    const uuu = 'https://rtmp01open.ys7.com:9188/v3/openlive/FH3271741_2_1.flv?expire=1732939592&id=782578242287566848&t=b5339f478bc7ca5765f90448b2223f9a331298c7dd6c0c9a3b8ade00901d4c55&ev=100'
    if ($video) {
      let {poster, autoplay, controls} = this.props;
      this.videoNode = $video;
      // if (false flvjs.isSupported() && this.props.flvUrl ) {
      if (false) {
        this.isFlvMode = true;
        let url = this.props.flvUrl.replace(/\.m3u8/gi, '.flv');
        console.log("🚀 ~ file:info ~ line:65 -----", url)
        console.log("🚀 ~ file:info ~ line:65 -----", uuu)
        url = uuu ;
        let option = {
          url: url,
          type:'flv',
          // controls: true,
          // autoplay: autoplay,
          poster: poster
        };

        let flvPlayer = flvjs.createPlayer({...option }, this.config);
        flvPlayer.attachMediaElement($video);
        flvPlayer.load();
        this.player = flvPlayer;
        try {
          this.play();
        }catch (e) {
        }
      } else {
        let url = this.props.m3u8Url.replace(/\.flv/gi, '.m3u8');
        console.log("🚀 ~ file:info ~ line:86 -----", url)
        console.log("🚀 ~ file:info ~ line:86 -----", uuu)
        url = uuu
        let option = {
          poster: poster,
          autoplay: autoplay,
          // controls: false,
          // controls: true,
          textTrackDisplay : false,
          errorDisplay : false,
          sources: [{
            src: url,
            // type: ''
          }]
        };
        this.player = videojs($video, option, ()=> {
          // console.log('onPlayerReady', this)
          // if(this.player && this.player.play) this.player.play();
          // if(this.player && this.player.load) this.player.load();
        });
      }

      if(this.videoNode) {
        // console.log(this.videoNode);
        this.videoNode.addEventListener('playing', () => {
          this.setState({
            playing: true,
          });
        });
      }

    }
  };

  play = ()=>{
    if(this.player && this.player.play){
      this.player.play();
      // this.setState({
      //   playing: true,
      // })
    }

  };

  fullScreen = () =>{
    if(this.videoNode){
      if (this.videoNode.requestFullscreen) {
        this.videoNode.requestFullscreen();
      } else if (this.videoNode.webkitRequestFullScreen) {
        this.videoNode.webkitRequestFullScreen();
      } else if (this.videoNode.mozRequestFullScreen) {
        this.videoNode.mozRequestFullScreen();
      } else if (this.videoNode.msRequestFullscreen) {
        this.videoNode.msRequestFullscreen();
      }
    }
  }

  render() {
    const { className, style, videoMirror } = this.props;
    const { playing } = this.state;
    let p = (<></>);
    if(!playing) {
      p = <div className="play"></div>
    }
    let videoMirrorClass = '';
    if(videoMirror && videoMirror === 2) {
      videoMirrorClass = 'video-mirror'
    }
    return (
      <div onClick={this.play} className="my-player">
        <div data-vjs-player>
          <video ref={this.initVideo} className={`video-js ${className} ${videoMirrorClass}`} webkit-playsinline="true" playsInline style={Object.assign({
            width: '100%',
            height: 'auto',
          }, style)} />
          {p}
          {/*<div className="full" onClick={this.fullScreen}>全屏</div>*/}
        </div>
      </div>
    )
  }
}
